import React from 'react';

import PageLayout from '../../views/Layout/Page';
import UserProfilePage, { UserProfileTabs } from '../../views/User/MyProfilePage';

export default function MyProfilePage() {
  return (
    <PageLayout pageview="my_profile_company" private>
      <UserProfilePage tab={UserProfileTabs.COMPANY_DETAILS} />
    </PageLayout>
  );
}
